Vue.http.interceptors.push({

   request: function (request) {
        return request;
    },
    
    response: function (response) {
        if (response.status == 401)
            window.location = '/';
        return response;
    }  
});
