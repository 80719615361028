Vue.use(window['vue-selectize']);

new Vue({
    el: 'body#florist-orders',

    data: {
        orders: false,
        filter: {},
        expandOrders: false,
        ordersExpanded: 0,
        sortKey: '',
        reverse: -1,
        orderModal: {
            show: false,
            order: {}
        },
        queringCities: false,
        cities: false,
        selectedPicture: false
    },

    ready: function() {
        this.getOrders('from_today');
    },

    computed: {
        total: function() {
            total = 0;
            _.each(this.orders, function(order) {
                if (order.total_flor)
                    total += parseFloat(order.total_flor);
                if (order.total_cust)
                    total -= parseFloat(order.total_cust);
            });

            return total;
        }
    },

    methods: {
        getOrders: function(param) {
            this.orders = false;
            this.$http.get('/home/orders/get/'+param, function(orders) {
                this.ordersExpanded = 0;
                orders = this.setOrdersProps(orders);
                this.orders = orders;
            });
        },

        sortBy: function(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? -this.reverse : 1;
            this.sortKey = sortKey;
        },

        filterOrders: function(filter) {
            this.orders = false;
            this.$http.post('/home/orders/filter', filter, function(orders) {
                this.ordersExpanded = 0;
                this.filter = {};
                orders = this.setOrdersProps(orders);
                this.orders = orders;
            })
        },

        toggleOrder: function(order) {
            order.showDetails = !order.showDetails;

            if (order.showDetails === true) {
                this.ordersExpanded++;
            } else
                this.ordersExpanded--;
        },

        toggleOrders: function() {
            this.expandOrders = !this.expandOrders;
            var that = this;
            _.each(this.orders, function(order) {
                order.showDetails = that.expandOrders;
            });
            this.ordersExpanded = 0;
        },

        confirmOrder: function(order) {
            var order = order;
            this.$http.put('/home/orders/'+order.reference+'/confirm')
                .then(function(res) {
                    if (res.data.success) {
                        order.confirmed_at = moment().format('YYYY-MM-DD HH:mm');
                        toastr.success(
                             res.data.success,
                            'Потвърдена'
                         );
                    }
                    if (res.data.error)
                        toastr.error(
                            res.data.error,
                            'Грешка'
                         );
                });
        },

        prepareOrder: function(order) {
            var order = order;
            this.$http.put('/home/orders/'+order.reference+'/prepare')
                .then(function(res) {
                    if (res.data.success) {
                        order.prepared_at = moment().format('YYYY-MM-DD HH:mm');
                        toastr.success(
                             res.data.success,
                            'Потготвена'
                         );
                    }
                    if (res.data.error)
                        toastr.error(
                            res.data.error,
                            'Грешка'
                         );
                });
        },

        deliverOrder: function(order) {
            var order = order;
            this.$http.put('/home/orders/'+order.reference+'/deliver')
                .then(function(res) {
                    if (res.data.success) {
                        order.notDelivered = false;
                        order.delivered_at = moment().format('YYYY-MM-DD HH:mm');
                        toastr.success(
                             res.data.success,
                            'Доставена'
                         );
                    }
                    if (res.data.error)
                        toastr.error(
                            res.data.error,
                            'Грешка'
                         );
                });
        },

        showOrderModal: function() {
            $('body').addClass('modal-opened');
            this.orderModal.show = true;
        },

        closeOrderModal: function() {
            $('body').removeClass('modal-opened');
            this.orderModal.show = false;
        },

        showPicture: function() {
            picture = this.$els.picture.files[0];
            this.selectedPicture = picture;
        },

        getDeliveryCities: function(query) {
            if (query.length > 2) {
                this.queringCities = true;

                var data = {
                    string: query
                };

                this.$http.post('/api/get_cities', data, function(response) {
                    this.queringCities = false;
                    this.cities = response;
                });
            } else {
                this.queringCities = false;
                this.cities = false;
            }
        },

        setOrdersProps: function(orders) {
            now = moment.tz('Europe/Sofia');
            var today = now.format('YYYY-MM-DD');
            var tomorrow = now.add(1, 'day').format('YYYY-MM-DD');
            _.each(orders, function(order) {
                order.notDelivered =
                    (moment(order.delivery_date).isBefore(today, 'day') && !order.delivered_at) ? true: false;
                order.today = 
                    moment(order.delivery_date).isSame(today, 'day');
                order.tomorrow = 
                    moment(order.delivery_date).isSame(tomorrow, 'day');
                order.showDetails = false;
            });

            return orders;
        }
    },

    filters: {
        limit: function(value, limit) {
            if (value)
                return value.substr(0, limit) + '...';
        },

        mysqlDateFilter: function(value) {
            value = value.split(' ')[0];
            return value.split('-').reverse().join('/');
        },

        moment: function(date, format) {
            return moment(date).tz('Europe/Sofia').format(format);
        }
    }
});
