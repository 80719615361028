new Vue({
    el: '#carrier-dashboard',
    data: {
        orders: false,
        ordersExpanded: 0,
        expandOrders: false,
        sortKey: 'delivery_hour',
        reverse: 1
    },
    ready: function() {
        this.getOrders('today');
    },
    methods: {
        getOrders: function(param) {
            this.orders = false;
            this.$http.get('/carrier/orders/'+param, function(orders) {
                orders = this.setOrdersProps(orders);
                this.orders = orders;
                this.expandOrders = false;
                this.ordersExpanded = 0;

                if (param == 'next_30_days') {
                    this.sortKey = 'delivery_date';
                } else {
                    this.sortKey = 'delivery_hour';
                }
                this.reverse = 1;
            });
        },

        sortBy: function(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? -this.reverse : 1;
            this.sortKey = sortKey;
        },
        
        toggleOrder: function(order) {
            order.showDetails = !order.showDetails;

            if (order.showDetails === true)
                this.ordersExpanded++;
            else
                this.ordersExpanded--;
        },

        toggleOrders: function() {
            this.expandOrders = !this.expandOrders;
            var that = this;
            _.each(this.orders, function(order) {
                order.showDetails = that.expandOrders;
            });
            this.ordersExpanded = 0;
        },

        confirmOrder: function(order) {
            var order = order;
            this.$http.put('/carrier/orders/'+order.reference+'/confirm')
                .then(function(res) {
                    if (res.data.success) {
                        order.confirmed_at = moment().format('YYYY-MM-DD HH:mm');
                        toastr.success(
                             res.data.success,
                            'Потвърдена'
                         );
                    }
                    if (res.data.error)
                        toastr.error(
                            res.data.error,
                            'Грешка'
                         );
                })
        },

        prepareOrder: function(order) {
            var order = order;
            this.$http.put('/carrier/orders/'+order.reference+'/prepare')
                .then(function(res) {
                    if (res.data.success) {
                        order.prepared_at = moment().format('YYYY-MM-DD HH:mm');
                        toastr.success(
                             res.data.success,
                            'Подготвена'
                         );
                    }
                    if (res.data.error)
                        toastr.error(
                            res.data.error,
                            'Грешка'
                         );
                })
        },

        deliverOrder: function(order) {
            var order = order;
            this.$http.put('/carrier/orders/'+order.reference+'/deliver')
                .then(function(res) {
                    if (res.data.success) {
                        order.notDelivered = false;
                        order.confirmed_at = moment().format('YYYY-MM-DD HH:mm');
                        order.prepared_at = moment().format('YYYY-MM-DD HH:mm');
                        order.delivered_at = moment().format('YYYY-MM-DD HH:mm');
                        toastr.success(
                             res.data.success,
                            'Доставена'
                         );
                    }
                    if (res.data.error)
                        toastr.error(
                            res.data.error,
                            'Грешка'
                         );
                })
        },

        setOrdersProps: function(orders) {
            now = moment.tz('Europe/Sofia');
            var today = now.format('YYYY-MM-DD');
            var tomorrow = now.add(1, 'day').format('YYYY-MM-DD');
            _.each(orders, function(order) {
                order.notDelivered =
                    (moment(order.delivery_date).isBefore(today, 'day') && !order.delivered_at) ? true: false;
                order.today = 
                    moment(order.delivery_date).isSame(today, 'day');
                order.tomorrow = 
                    moment(order.delivery_date).isSame(tomorrow, 'day');
                order.showDetails = false;
            });

            return orders;
        }
    },

    filters: {
        moment: function(value, format) {
            return moment(value).tz('Europe/Sofia').format(format);
        }
    }
});
