Vue.transition('bounce', {
    enterClass: 'bounceIn',
    leaveClass: 'bounceOut'
});
Vue.transition('flipY', {
    enterClass: 'flipInY',
    leaveClass: 'flipOutY'
});
Vue.transition('flipX', {
    enterClass: 'flipInX',
    leaveClass: 'flipOutX'
});
Vue.transition('zoom', {
    enterClass: 'zoomInDown',
    leaveClass: 'zoomOutUp'
});
Vue.transition('fade', {
    enterClass: 'fadeIn',
    leaveClass: 'fadeOut'
});
