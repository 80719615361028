new Vue({
  el: '#carrier-reviews',

  data: {
    reviews: false
  },

  ready: function() {
    this.getReviews();
  },

  methods: {
    getReviews: function() {
      this.reviews = false;
      this.$http.get('/carrier/reviews/get', function(reviews) {
        this.reviews = reviews;
      });
    }
  },

  filters: {
    mysqlDateTimeFilter: function(value) {
      if (!value)
        return 'N/A';
      if (value.date)
        value = value.date;
      var parts = value.split(" ");
      var date = parts[0].split('-').reverse().join('/');
      var time = parts[1].split(':');
      time = time[0] + ':' + time[1];
      return date + ' ' + time;
    },
    limit: function(string, limit, ending) {
      return string.substr(0, limit) + ending;
    }
  }

});
