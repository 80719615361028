Vue.use(window['vue-selectize']);

new Vue({
    el: '#florist-payments',

    data: {
        payments: false,
        filter: {},
        expandPayments: false,
        paymentsExpanded: 0
    },

    ready: function() {
        this.getPayments();
    },

    methods: {
        getPayments: function() {
            this.payments = false;
            this.$http.get('/home/payments/get', function(payments) {
                this.paymentsExpanded = 0;
                payments = this.setPaymentsProps(payments);
                this.payments = payments;
            });
        },
        
        filterPayments: function() {
            this.payments = false;
            this.$http.post('/home/payments/filter', this.filter, function(payments) {
                this.filter = {};
                this.paymentsExpanded = 0;
                payments = this.setPaymentsProps(payments);
                this.payments = payments;
            });
        },

        togglePayment: function(payment) {
            payment.showDetails = !payment.showDetails;    

            if (payment.showDetails === true) {
                this.paymentsExpanded++;
                this.getPaymentData(payment);
            } else {
                this.paymentsExpanded--;
                payment.paymentData = false;
            }
        },

        togglePayments: function() {
            this.expandPayments = !this.expandPayments;
            var that = this;
            _.each(this.payments, function(payment) {
                payment.showDetails = that.expandPayments;
                if (payment.type == 'payment')
                    that.getPaymentData(payment);
            });
            this.paymentsExpanded = 0;
        },

        getPaymentData: function(payment) {
            this.$http.get('/home/payments/'+payment.id+'/data', function(response) {
                total_orders = 0;
                total_payments = 0;
                _.each(response.orders, function(order) {
                    total_orders += parseFloat(order.total_flor);
                });
                _.each(response.payments, function(payment) {
                    total_payments += parseFloat(payment.total_paid);
                });
                payment.paymentData = response;
                payment.paymentData.total_orders = total_orders;
                payment.paymentData.total_payments = total_payments;
            });
        },

        setPaymentsProps: function(payments) {
            _.each(payments, function(payment) {
                payment.showDetails = false;
                payment.paymentData = false;
            });

            return payments;
        }
    },

    filters: {
        moment: function(value, format) {
            return moment(value).format(format);
        },

        momentHuman: function(value) {
            return moment(value).fromNow();
        }
    }
});
