Vue.use(window['vue-selectize']);

new Vue({
    el: '#florist-settings',

    data: {
        paymentMethods: false,
        carriers: false,
        accountModal: {
            show: false,
            title: 'Нов метод за разплащане',
            icon: 'fa-plus',
            method: 'POST',
            action: '/home/settings/accounts',
            account: {}
        },
        carrierModal: {
            show: false,
            title: 'Добави куриер',
            icon: 'fa-plus',
            method: 'POST',
            action: '/home/settings/carriers',
            carrier: {}
        },
    },

    ready: function() {
        this.getPaymentMethods();
        this.getCarriers();
    },

    methods: {
        getPaymentMethods: function() {
            this.paymentMethods = false;
            this.$http.get('/home/settings/payment_methods', function(response) {
                this.paymentMethods = response;
            });
        },

        getCarriers: function() {
            this.carriers = false;
            this.$http.get('/home/settings/carriers', function(carriers) {
                this.carriers = carriers;
            });
        },
        
        setAsDefault: function(account) {
            _.each(this.paymentMethods, function(acc) {
                acc.default = false;
            });
            account.default = true;
            this.$http.put('/home/settings/payment_methods/'+account.id, account);
        },

        unsetAsDefault: function(account) {
            account.default = false;
            this.$http.put('/home/settings/payment_methods/'+account.id, account);
        },
        
        addAccount: function() {
            this.accountModal = {
                show: true,
                title: 'Нов метод за разплащане',
                icon: 'fa-plus',
                method: 'POST',
                action: '/home/settings/accounts',
                account: {}
            };
        },

        editAccount: function(account) {
            this.accountModal = {
                show: true,
                title: 'Редакция на метод за разплащане',
                icon: 'fa-edit',
                method: 'PUT',
                action: '/home/settings/accounts/' + account.id,
                account: account
            };
        },

        deleteAccount: function(account) {
            var conf = confirm('Сигурен ли си, че искаш да изтриеш сметката');

            if (conf == true) {
                this.paymentMethods.$remove(account);
                this.$http.delete('/home/settings/account/'+account.id);
            }
        },

        addCarrier: function() {
            this.carrierModal = {
                show: true,
                title: 'Добави куриер',
                icon: 'fa-plus',
                method: 'POST',
                action: '/home/settings/carriers',
                carrier: {}
            };
        },

        editCarrier: function(carrier) {
            this.carrierModal = {
                show: true,
                title: 'Редакция на куриер',
                icon: 'fa-edit',
                method: 'PUT',
                action: '/home/settings/carriers/' + carrier.id,
                carrier: carrier
            };
        },

        enableCarrier: function(carrier) {
            carrier.enabled = 1;
            this.$http.put('/home/settings/carriers/'+carrier.id+'/enable');
        },

        disableCarrier: function(carrier) {
            carrier.enabled = 0;
            this.$http.put('/home/settings/carriers/'+carrier.id+'/disable');
        },

        deleteCarrier: function(carrier) {
            var conf = confirm('Сигурен ли си, че искаш да изтриеш куриера?');

            if (conf == true) {
                this.carriers.$remove(carrier);
                this.$http.delete('/home/settings/carriers/'+carrier.id);
            }
        }
    }
});
